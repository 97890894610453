import React, { useEffect, useState } from 'react'
import { Link, graphql, useStaticQuery } from 'gatsby'
import loadable from '@loadable/component'
import AccessAyalaPopUp from '../../assets/images/access-ayala/access-ayala-popup.png'

const Layout = loadable(() => import('../../components/Layout/Layout'))
const SEO = loadable(() => import('../../components/SEO/SEO'))
const BannerCarousel = loadable(() => import('../../components/HomePage/BannerCarousel/BannerCarousel'))
const SpacesFold = loadable(() => import('../../components/HomePage/SpacesFold/SpacesFold'))

import './Main.scss'
import Modal from '../../hooks/ClosableDiv/Closable'

const Main = ({ pageContext: { data, metaData } }: any): JSX.Element => {
  const homeBannerContent = data.home_banners[0]
  const homepage = useStaticQuery(graphql`
    query {
      homePageBanner {
        gallery {
          meta_data {
            alt_text
          }
        }
        optimizedImgs {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
          }
        }
      }
      seoTags {
        containers {
          field_collections {
            fields {
              name
              value
            }
          }
        }
      }
    }
  `)

  const getImageWithAltText = (): Array<any> => {
    const optimizedImages = homepage?.homePageBanner.optimizedImgs
    const imageAltText = homepage?.homePageBanner.gallery

    return optimizedImages.map((image: any, index: number) => ({
      image,
      altText: imageAltText[index]
    }))
  }

  const getContent = (name: any) => {
    const content = homepage.seoTags?.containers[0]?.field_collections[0].fields.find((field: any) => field.name === name)
    return content ? content.value : ''
  }
  const [closeModal, setCloseModal] = useState(true)

  const seoTitle = getContent('seo-home-title')
  const seoDescription = getContent('seo-home-description')
  const headingTag = getContent('home-heading-tag')

  useEffect(() => {
    if (sessionStorage.getItem('close')) {
      setCloseModal(true)
    } else {
      setCloseModal(false)
    }
  }, [])

  const handleClick = () => {
    sessionStorage.setItem('close', 'true')
    setCloseModal(true)
  }

  return (
    <Layout>
      <SEO
        title={seoTitle ? seoTitle : 'Home - Ayala Land Premier'}
        description={seoDescription ? seoDescription : metaData?.description}
      />
      {/* {!closeModal ? (
        <Modal onClose={() => handleClick()}>
          <Link to="https://access.ayalaland.com/" className="access-ayala">
            <button
              className="access-ayala-close-button"
              onClick={(e) => {
                e.preventDefault()
                handleClick()
              }}
            >
              <span>&times;</span>
            </button>
            <img src={AccessAyalaPopUp} alt="access ayala pop up" />
          </Link>
        </Modal>
      ) : null} */}
      <div className="slider-fixed-container">
        <BannerCarousel text="spaces of distinction" images={getImageWithAltText()} hover={true} />
      </div>
      <div className="page-content">
        <SpacesFold content={homeBannerContent} />
      </div>
    </Layout>
  )
}

export default Main
